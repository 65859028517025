// @ts-ignore
import Alpine from 'alpinejs';



Alpine.start()
// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
